<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-book-account</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varCaixaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="varCaixaLocal.tipo_pessoa"
                    label="Tipo Pessoa"
                    outlined
                    :items="['Fisica', 'Juridica']"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="8" align-self="center">
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="varCaixaLocal.id_pessoa"
                    :label="compLabelSelect"
                    outlined
                    :items="compItensSelect"
                    item-text="nome"
                    item-value="id"
                    filterable
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varCaixaLocal.cpf_cnpj"
                    :label="
                      varCaixaLocal.tipo_pessoa === 'Fisica' ? 'CPF' : 'CNPJ'
                    "
                    v-mask="
                      varCaixaLocal.tipo_pessoa === 'Fisica'
                        ? '###.###.###-##'
                        : '##.###.###/####-##'
                    "
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSalvar"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>

          <template>
            <v-divider></v-divider>
            <v-row class="ml-4">
              <v-col cols="auto" sm="6">
                <div class="caption">
                  Criação:
                  {{ varCaixaLocal.criacao }}
                  - {{ mixDataDBT(varCaixaLocal.criado) }}
                </div>
              </v-col>
              <v-col cols="auto" sm="6">
                <div class="caption">
                  Modificação:
                  {{ varCaixaLocal.modificacao }} -
                  {{ mixDataDBT(varCaixaLocal.modificado) }}
                </div>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("contCaixas");

export default {
  mixins: [validationMixin, DatasMixin],

  data: () => ({
    name: "ContCaixasForm",
    tituloForm: "",
    varCaixaLocal: {},
  }),

  computed: {
    ...mapState([
      "staCaixas",
      "staCaixaSelecionado",
      "staCaixaSelect",
      "staPFisicas",
      "staPJuridicas",
    ]),

    compItensSelect() {
      let itensDisponiveis = [];
      if (this.varCaixaLocal.tipo_pessoa === "Fisica") {
        itensDisponiveis = this.staPFisicas;
      } else if (this.varCaixaLocal.tipo_pessoa === "Juridica") {
        itensDisponiveis = this.staPJuridicas;
      }

      return itensDisponiveis.filter(
        (pessoa) =>
          pessoa.id === this.varCaixaLocal.id_pessoa ||
          !this.staCaixas.some(
            (caixa) =>
              caixa.id_pessoa === pessoa.id &&
              caixa.tipo_pessoa === this.varCaixaLocal.tipo_pessoa
          )
      );
    },

    compLabelSelect() {
      return this.varCaixaLocal.tipo_pessoa === "Fisica"
        ? "Nome"
        : "Razão Social";
    },
  },

  watch: {
    "varCaixaLocal.id_pessoa": function (newVal) {
      this.metPreencherCpfCnpj(newVal);
    },

    staCaixaSelecionado(newVal) {
      this.metSincronizar(newVal);
    },
  },

  created() {
    this.metSincronizar(this.staCaixaSelecionado);
    this.metCarregarPessoas();

    if (Object.keys(this.staCaixaSelecionado).length === 0) {
      this.tituloForm = "Nova Caixa";
    } else {
      this.tituloForm = "Editar Caixa";
    }

    if (this.varCaixaLocal.id_pessoa) {
      this.metPreencherCpfCnpj(this.varCaixaLocal.id_pessoa);
    }
  },

  methods: {
    ...mapActions([
      "actCaixaSelecionado",
      "actResetarCaixa",
      "actCaixaInsert",
      "actCaixaUpdate",
      "actResetarErro",
      "actAtualizaPFisicas",
      "actAtualizaPJuridicas",
    ]),

    metPreencherCpfCnpj(idPessoa) {
      if (this.varCaixaLocal.tipo_pessoa === "Fisica") {
        const pf = this.staPFisicas.find((pf) => pf.id === idPessoa);
        if (pf) {
          this.varCaixaLocal.cpf_cnpj = pf.cpf; // Assumindo que o campo se chama 'cpf'
        }
      } else if (this.varCaixaLocal.tipo_pessoa === "Juridica") {
        const pj = this.staPJuridicas.find((pj) => pj.id === idPessoa);
        if (pj) {
          this.varCaixaLocal.cpf_cnpj = pj.cnpj; // Assumindo que o campo se chama 'cnpj'
        }
      }
    },

    async metCarregarPessoas() {
      await this.actAtualizaPFisicas({
        licenca: this.$store.state.login.licenca,
      });
      await this.actAtualizaPJuridicas({
        licenca: this.$store.state.login.licenca,
      });
    },

    async metSalvar() {
      if (this.varCaixaLocal.id === undefined) {
        // Insert
        const pessoaSelecionada = this.compItensSelect.find(
          (p) => p.id === this.varCaixaLocal.id_pessoa
        );
        this.varCaixaLocal.nome_razao_social = pessoaSelecionada.nome;

        this.varCaixaLocal.criacao = this.$store.state.login.usuario.usuario;
        this.varCaixaLocal.modificacao =
          this.$store.state.login.usuario.usuario;
        await this.actCaixaInsert({
          caixa: this.varCaixaLocal,
          licenca: this.$store.state.login.licenca,
        });
        this.tituloForm = "Editar Caixa";
        this.$root.snackbar.show({
          type: "success",
          message: "Caixa Criada!",
        });
      } else {
        // Update
        const pessoaSelecionada = this.compItensSelect.find(
          (p) => p.id === this.varCaixaLocal.id_pessoa
        );
        this.varCaixaLocal.nome_razao_social = pessoaSelecionada.nome;
        this.varCaixaLocal.modificacao =
          this.$store.state.login.usuario.usuario;
        this.varCaixaLocal.modificado = undefined;
        this.varCaixaLocal.criado = undefined;
        await this.actCaixaUpdate({
          caixa: this.varCaixaLocal,
          licenca: this.$store.state.login.licenca,
        });

        this.$root.snackbar.show({
          type: "success",
          message: "Caixa Salva!",
        });
      }
      //this.resetar();
    },

    metSincronizar(caixa) {
      this.varCaixaLocal = Object.assign({}, caixa || {});
    },

    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
